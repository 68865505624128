import React from 'react'
import logo from './AnvitaPandey.png'
import { Link } from '../../../i18n'

import styles from './logo.module.scss'
import { FormattedMessage } from 'react-intl'


const Logo = () => {
  return (
    <Link to="/" className={styles.logo}>
      <img src={logo} alt="Anvita Pandey"/>
      <span className="sr-only">
        <FormattedMessage id="title">{(...title) => title}</FormattedMessage>
      </span>
    </Link>
  )
}

export default Logo
