const localeData = [
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/de'),
]

const languages = [
  { value: 'en', text: 'EN' },
  { value: 'de', text: 'DE' },
]

module.exports = {
  localeData,
  languages,
  fallback: 'en'
}
