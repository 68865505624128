import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { injectIntl } from 'react-intl'

import Header from '../Header'
import Footer from '../Footer'

import 'typeface-lato'
import './layout.scss'

const Layout = props => {
  const { children, intl } = props

  const title = intl.formatMessage({ id: 'title' })
  const tagline = intl.formatMessage({ id: 'tagline' })
  const description = intl.formatMessage({ id: 'description' })

  return (
    <React.Fragment>
      <Helmet
        defaultTitle={`${title} | ${tagline}`}
        titleTemplate={`%s | ${title} | ${tagline}`}
      >
        <html lang={intl.locale} />
        {props.title && (
          <title>{intl.formatMessage({ id: props.title })}</title>
        )}
        <meta name="description" content={description} />
        <meta name="og:title" content={`${title} | ${tagline}`} />
        <meta name="og:description" content={description} />
      </Helmet>

      <Header />

      <div className="site-content">{children}</div>

      <Footer />

    </React.Fragment>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
