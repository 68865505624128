import React from 'react'

import { Consumer } from '../../../i18n/Context'
import { ReactComponent as FlagEN } from './flag-en.svg'
import { ReactComponent as FlagDE } from './flag-de.svg'

import styles from './languageSwitcher.module.scss'

const flags = {
  en: <FlagEN />,
  de: <FlagDE />,
}

const LanguageSwitcher = () => (
  <Consumer>
    {({ locale, languages, onLocaleChange }) => {
      if (!languages) return null

      return (
        <ul className={styles.list}>
          {languages.map(({ value, text }) => {
            const classes = value === locale ? styles.isCurrent : null

            return (
              <li key={value} className={classes}>
                <button onClick={() => onLocaleChange(value)}>
                  {flags[value]}
                  {/*text*/}
                </button>
              </li>
            )
          })}
        </ul>
      )
    }}
  </Consumer>
)

export default LanguageSwitcher
