import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import styles from './contentBlock.module.scss'

const ContentBlock = ({ content, specialClass, dark, intl }) => {
  const classes = classNames(styles.wrap, {[styles[specialClass]]: specialClass}, { [styles.dark]: dark })

  return (
    <section className={classes}>
      <div className={styles.container}>
        {content.map(c => (
          <p key={c}>{intl.formatHTMLMessage({ id: c })}</p>
        ))}
      </div>
    </section>
  )
}

// Pass array of translation keys
ContentBlock.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default injectIntl(ContentBlock)
