import React, { Component } from 'react'
import classNames from 'classnames'
import ListItem from './ListItem'

import styles from './navigation.module.scss'

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = { open: null }

    this.closeTimer = null

    this.handleWindowClose = this.handleWindowClose.bind(this)
  }

  componentDidMount() {
    window.addEventListener('click', this.handleWindowClose)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowClose)
  }

  handleWindowClose = e => {
    if (this.state.open !== null && !e.target.closest('header')) {
      this.setState({ open: null })
    }
  }

  handleToggle = index => {
    this.setState(prevState => ({
      open: prevState.open === index ? null : index,
    }))
  }

  handleMouseIn = () => {
    clearTimeout(this.closeTimer)
    this.closeTimer = null
  }

  handleMouseOut = () => {
    this.closeTimer = setTimeout(() => {
      this.setState({ open: null })
    }, 500)
  }

  render() {
    const aboutMenu = classNames(styles.subMenuWrap, {
      [styles.isOpen]: this.state.open === 0,
    })

    const performanceMenu = classNames(styles.subMenuWrap, {
      [styles.isOpen]: this.state.open === 1,
    })

    const newsMenu = classNames(styles.subMenuWrap, {
      [styles.isOpen]: this.state.open === 2,
    })

    return (
      <nav className={styles.navigation}>
        <ul className={styles.topMenu}>
          <ListItem
            index={0}
            to="/"
            text="menu.about"
            parent
            onClick={this.handleToggle}
            isOpen={this.state.open === 0}
          >
            <div
              className={aboutMenu}
              onMouseEnter={this.handleMouseIn}
              onMouseLeave={this.handleMouseOut}
            >
              <ul className={styles.subMenu}>
                <ListItem
                  to="/about-kathak"
                  text="menu.about.kathak"
                />
                <ListItem
                  to="/about-anvita"
                  text="menu.about.anvita"
                />
                <ListItem
                  to="/about-shovana-narayan"
                  text="menu.about.shovana"
                />
                <ListItem
                  to="/about-sharmila-sharma"
                  text="menu.about.sharmila"
                />
              </ul>
            </div>
          </ListItem>

          <ListItem
            index={1}
            to="/"
            text="menu.performances"
            parent
            onClick={this.handleToggle}
            isOpen={this.state.open === 0}
          >
            <div
              className={performanceMenu}
              onMouseEnter={this.handleMouseIn}
              onMouseLeave={this.handleMouseOut}
            >
              <ul className={styles.subMenu}>
                <ListItem
                  to="/performances-photos"
                  text="menu.performances.photos"
                />
                <ListItem
                  to="/performances-videos"
                  text="menu.performances.videos"
                />
              </ul>
            </div>
          </ListItem>

          <ListItem to="/teaching" text="menu.teaching" />

          <ListItem
            index={2}
            to="/"
            text="menu.news"
            parent
            onClick={this.handleToggle}
            isOpen={this.state.open === 0}
          >
            <div
              className={newsMenu}
              onMouseEnter={this.handleMouseIn}
              onMouseLeave={this.handleMouseOut}
            >
              <ul className={styles.subMenu}>
                <ListItem
                  to="/news"
                  text="menu.news.upcoming"
                />
                <ListItem
                  to="/baithak"
                  text="menu.news.baithak"
                />
                <ListItem
                  to="/women-speak"
                  text="menu.news.womenspeak"
                />
                <ListItem
                  to="/serendipity"
                  text="menu.news.serendipity"
                />
              </ul>
            </div>
          </ListItem>

          <ListItem to="/contact" text="menu.contact" />
        </ul>
      </nav>
    )
  }
}

export default Navigation
