import React from 'react'

import { ReactComponent as OpenIcon } from './open-icon.svg'
import { ReactComponent as CloseIcon } from './close-icon.svg'

import styles from './mobileMenuToggle.module.scss'
import { FormattedMessage } from 'react-intl'

const MobileMenuToggle = ({ isOpen, onClick }) => (
  <button type="button" className={styles.button} onClick={onClick}>
    {isOpen ? (
      <React.Fragment>
        <CloseIcon className={styles.closeIcon} />
        <span className="sr-only">
          <FormattedMessage id="close.menu" />
        </span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <OpenIcon className={styles.openIcon} />
        <span className="sr-only">
          <FormattedMessage id="open.menu" />
        </span>
      </React.Fragment>
    )}
  </button>
)

export default MobileMenuToggle
