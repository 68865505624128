module.exports = {
  title: 'Anvita Pandey',
  tagline: 'Kathak Dancer in Zürich, Switzerland',
  description: 'Anvita Pandey is a Kathak dancer based in Zürich, Switzerland. She conducts dance classes in Thalwil, Zug and Zürich.',

  // Header
  'menu.about': 'Über',
  'menu.about.kathak': 'Kathak',
  'menu.about.anvita': 'Anvita Pandey',
  'menu.about.shovana': 'Shovana Narayan',
  'menu.about.sharmila': 'Sharmila Sharma',
  'menu.teaching': 'Teaching',
  'menu.news': 'News',
  'menu.news.upcoming': 'Upcoming',
  'menu.news.baithak': 'Baithak',
  'menu.news.womenspeak': 'Women Speak',
  'menu.news.serendipity': 'Serendipity',
  'menu.contact': 'Kontakt',
  'menu.performances': 'Performances',
  'menu.performances.photos': 'Photos',
  'menu.performances.videos': 'Videos',
  'menu.imprint': 'Impressum',
  'menu.data.protection': 'Datenschutz',

  // Footer
  'copyright': 'Anvita Pandey {year}',
  'facebook': 'Facebook',
  'instagram': 'Instagram',
  'youtube': 'Youtube',

  // Home Page
  // 'home.subtitle': 'A professional and versatile Kathak dancer, Anvita Pandey started training under the tutelage of Sangeet Natak Akademi awardee and Padma Shri Guru Shovana Narayan...',
  'home.other': '',
  'home.upcoming': 'Upcoming Events & Classes',

  // About Kathak Page
  'about.kathak.subtitle': 'Kathak',
  'about.kathak.1': 'Kathak is an Indian classical dance from North India. Essentially an art of storytelling, it derives its name from the word “Katha” meaning story, narrated by “Kathakaars” or storytellers. It is rooted in “Natya Shastra”, going back almost 2000 years in history. It originated in the temples of North India, evolving during Bhakti movement and then flourishing in Mughal courts, further developing and refining its technique. Stylistically, Kathak emphasizes on rhythmic footwork, delicate hand movements, subtle face expressions and fast pirouettes ending in a graceful stance.',

  // About Anvita Page
  'about.anvita.subtitle': 'Anvita Pandey',
  'about.anvita.1': 'A professional and versatile performer, Anvita Pandey specializes in the North Indian classical dance Kathak. Trained since a young age under the tutelage of Sangeet Natak akademi awardee and Padma Shri Guru Shovana Narayan, she learnt the nuances of both Lucknow and Jaipur gharana from her Guru under ‘Guru Shishya Parampara’. With a strong foundation and training of more than 10 years, she has performed in various prestigious festivals and cities across India with her Guru’s repertory dance company.',
  'about.anvita.2': 'Anvita holds advanced degrees with distinction from Prayag Sangit Samiti, Allahabad and ISTD (Imperial Society of Teachers Dancing), London. Although an Environment Management professional, after moving to Switzerland, dance became her sole focus and she established herself as a successful solo performer.',
  'about.anvita.3': 'Anvita is also an upcoming choreographer and is interested in collaborations and exploring choreographies from different dance styles and movement artists. She is currently residing in Zürich, and has been actively performing, teaching and conducting workshops in and around Switzerland and Europe.',

  // About Shovana Page
  'about.shovana.subtitle': 'Shovana Narayan',
  'about.shovana.1': 'Shovana Narayan is an internationally renowned Kathak exponent, guru and a choreographer. She is a disciple of Pandit Birju Maharaj, and has created a niche of her own in the field of Kathak. A recipient of Padma Shri and Sangeet Natak Akademi award, she is a multi-faceted artist – an acclaimed author, researcher, civil servant (retired) and a classical dancer. A pioneer in international collaborative fusion projects, she is well known for her immense creativity and contribution in the field of performing arts. She has worked with many western dancers and musicians, some of her choreographic works like "Moonlight Impressionism" and "The Dawn After" have been successful in bringing out the true color of different dance and musical forms.',
  'about.shovana.2': 'She established the Asavari Centre for Kathak in Delhi, which nurtures talent and imparts Kathak training under Gurukul parampara (Guru shishya tradition). It has successfully created disciples who are now acclaimed dancers and spreading the art of Kathak worldwide and are also shining in the related fields of arts.',

  // About Sharmila Page
  'about.sharmila.subtitle': 'Sharmila Sharma',
  'about.sharmila.1': 'Born in Jaipur, Sharmila Sharma belongs to a family of artists. After her intitial training from her mother, who is both a Kathak and folk dancer, she joined the renowned Kathak Kendra in New Delhi where her talent blossomed under the tutelage of Pandit Rajendra Kumar Gangani (Jaipur style) and of Pandit Birju Maharaj (Lucknow style). She became a professional dancer at the young age of fourteen, and has been performing in international and national festivals and venues ever since.',
  'about.sharmila.2': 'She established herself in Paris in 1993, where she started teaching Kathak and now continues to  conduct workshops and giving Kathak recitals on a regular basis. A tireless dancer, she travels and performs, flitting between Europe and India, where her art is appreciated by connoisseurs as well as the general audience. Her dance beautifully combines and showcases the grace and elegance of Lucknow style and the technical wizardry of Jaipur gharana.',

  // Teaching Page
  'teaching.class.subtitle': 'Classes',
  'teaching.class.1': 'Anvita conducts weekly classes for children, young adults and adults in different groups of beginners and intermediate learners. Movement artists and experienced professionals from other dance styles are also welcome to join. To complement the training, related aspects of music such as taal (periodic time cycle) and lehra (melodic time cycle), rhythms, poetry, themes, and underlying expressions are also explored in detail. Emphasis is further laid on understanding of aesthetics and the rich history and tradition of this art form. To enable a stimulating learning environment, students are encouraged to be imaginative and to explore creative improvisation.',
  'teaching.class.2': 'The students are given an opportunity to showcase their learnings on a cultural platform, to develop their self-confidence and presentation skills.',
  'teaching.class.3': 'Presently Anvita is giving regular classes in Zurich, Thalwil and Zug. Private sessions or Kathak based choreographies can also be provided on request.',
  'teaching.workshop.subtitle': 'Workshops',
  'teaching.workshop.1': 'Anvita conducts workshops in Kathak which are tailored to suit the level of expertise of the participants. In past, she has given workshops at academic institutions like ETH Zurich, corporates like Google (at TGIF evenings) and also at various dance festivals like Vertanzt, Zurich Tanzt and Interkultur Fest.',


  // Misc.
  'read.more': 'Weiterlesen',
  'send': 'Senden',
  'close': 'Schließen',


  // Contact
  'contact.subtitle': 'Kontakt',
  'contact.location.1.title': 'Anvita Pandey',
  'contact.location.1.address': '8800 Thalwil',
  'contact.form.title': 'Wir freuen uns auf Sie',
  'contact.form.text': 'Would you like to host a workshop or a performance?',
  'contact.form.success': 'Thanks for contacting us. We will get back in touch shortly.',
  'form.name': 'Name',
  'form.email': 'E-Mail',
  'form.topic': 'Betreff',
  'form.message': 'Nachricht',
  'form.required': '*',

  // News
  'news.subtitle': 'News',
  'news.soon': 'Details Soon',

  'news.fribourg.2019': 'Fête de la Danse 2019, Fribourg',
  'news.zuritanzt.2019': 'Zürich Tanzt 2019',
  'news.ghent.2019': 'Women Speaks',
  'news.istanbul.2019': 'Kathak @ Takadhimi, Istanbul',


  'error.404.subtitle': '404',
  'error.404.title': 'Nicht Gefunden',
  'error.404.text': 'Leider existiert die von Ihnen gesuchte Seite nicht.',
}
