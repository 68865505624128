import React from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './footer.module.scss'

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>

        <small className={styles.copyright}>
          &copy;{' '}
          <FormattedMessage
            id="copyright"
            values={{ year: new Date().getFullYear() }}
          />
        </small>

    </div>
  </footer>
)

export default Footer
