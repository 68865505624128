import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Link } from '../../../../i18n'
import { ReactComponent as DownArrow } from '../icons/down-arrow.svg'
import { ReactComponent as DownArrowMobile } from '../icons/down-arrow-mobile.svg'

import styles from './listItem.module.scss'

const ListItem = props => {
  const classes = props.isOpen ? styles.isOpen : null

  const handleClick = e => {
    e.preventDefault()
    props.onClick(props.index)
  }

  return (
    <li className={classes}>
      <Link
        className={styles.link}
        to={props.to}
        onClick={props.onClick && handleClick}
      >
        <span className={styles.inner}>
          <FormattedMessage id={props.text} />

          {props.parent && (
            <span className={styles.downArrow}>
              <DownArrow className={styles.desktopArrow} />
              <DownArrowMobile className={styles.mobileArrow} />
            </span>
          )}
        </span>
      </Link>

      {props.children}
    </li>
  )
}

export default ListItem
