import React, { Component } from 'react'
import classNames from 'classnames'

import Logo from './Logo'
import MobileMenuToggle from './MobileMenuToggle'
import Navigation from './Navigation'
import LanguageSwitcher from './LanguageSwitcher'

import styles from './header.module.scss'

class Header extends Component {
  state = { open: false }

  handleOpen = () => this.setState(prevState => ({ open: !prevState.open }))

  render() {
    const navClasses = classNames(styles.navigation, {
      [styles.isOpen]: this.state.open,
    })

    return (
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo />

            <div className={styles.mobileToggle}>
              <MobileMenuToggle
                isOpen={this.state.open}
                onClick={this.handleOpen}
              />
            </div>
          </div>

          <div className={navClasses}>
            <Navigation />

            {
/*            <div className={styles.languageSwitcher}>
              <LanguageSwitcher />
            </div>
*/            }
          </div>
        </div>
      </header>
    )
  }
}

export default Header
